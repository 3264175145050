.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.top-grid {
  background-color: #686f79;
}
.top-grid .weui-grid__icon{
  filter: invert(100%);
}
.top-grid .weui-grid__label{
  color:white;
  font-weight: bold;
}

.pro-box-left  {margin: 0 auto;
  margin-left: 10px;
  margin-right: 5px;
  padding-top: 5rpx;
  overflow: hidden; clear: both; }


.pro-box-right {margin: 0 auto;
  margin-left: 5px;
  margin-right: 10px;
  padding-top: 5rpx;
    overflow: hidden; clear: both; }
 

.pro-img{
  width:100%;

}

.pro-item{
  display: block;
  margin-top: 10px;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.1);
  border-radius: 10px;
  background-color: white;
  box-sizing: border-box;
}

.pro-item .pro-img{ border-top-left-radius: 10px; border-top-right-radius: 10px;}
.pro-item .pro-title{color: #333;font-size: 30rpx;font-weight: bold;line-height: 42rpx;margin-left: 10rpx;margin-right: 10rpx;text-align: justify; }

.hmbProductTitle{
  padding: 0 15px;
}


.txy_show{
  width:100%
}


.weui-footer-login{
	position: absolute;
	bottom:0px;
	height:79px;
	border-top:1px solid #ddd;
	width:100%;
	background: #f7f7f7;
}



.hmb-product-tip{
  color: rgb(17, 17, 17);
  padding: 20px;
  font-weight: bold;
  font-size: 14px;
  margin-top: 14px;
  margin-bottom: 14px;
  font-weight: bold;
  text-align: left;
}


.hmb-product-idcard-image{
  height: 100px; display: block; margin-top: 8px; border: 1px solid rgb(180, 176, 176);
}

.hmb-uploader-tip{

  color: #333;
  font-size: 12px;
  text-align: left;
  display: inline-block;
  width: 100%;

}

.hmb-van-uploader {
  width: 100%;
  height: 115px;
  border: 1px dashed #e5e5e5;
}



.hmb-preview-pic {
  width: auto;
  height: 115px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.hmb-van-uploader__input {
width: 100%;
height: 100%;
cursor: pointer;
}