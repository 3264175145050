.hmb-trade-content{
    color: black;
  }
  
  .hmb-trade-updatetime{
    color: red;
  }

  .hmb-trade-buscode{
    color:blue
  }