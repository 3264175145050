.weui-flex-num {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px
  }
   
  .weui-flex-num__item {
    font-size: 0.8em;
    flex: 0 0 auto;
    width: 33%;
    padding: 5rpx 5rpx;
    box-sizing: border-box;
    text-align: center;
  }

  .choose-num{
    color: red;
  }